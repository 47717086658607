import React from 'react'
import { PageProps } from 'gatsby'
import { useApi } from '~/siteApi'
import { Head, Hero, Breadcrumb, EnterpriseSlider, Footer } from '~/components'
import Banner from '~/assets/img/Banner-Empreendimentos.jpg'

interface Context {
  enterprises: Array<{
    id: number
    name: string
    slug: string
    items: [
      {
        id: number
        name: string
        slug: string
        room: string
        neighborhood: string
        city: string
        state: string
        tag: string
        imageFeature: string
        imageSideFirst: string
        imageSideSecond: string
        imageSideThird: string
      }
    ]
  }>
}

const Enterprises = ({ location, pageContext }: PageProps) => {
  const { enterprises }: Context = useApi(pageContext, 'page-enterprise')

  return (
    <>
      <Head
        location={location}
        title={`Empreendimentos - ${process.env.GATSBY_SITE_NAME}`}
      />
      <Hero title="Empreendimentos" image={Banner} />
      <Breadcrumb />
      {enterprises.map(({ id, name, items }) => (
        <EnterpriseSlider
          key={id}
          title={name}
          enterprises={items}
          slider={false}
        />
      ))}
      <Footer />
    </>
  )
}

export default Enterprises
